import * as React from "react"
import LinktoFormButton from "../components/LinktoFormButton"
import Logo from "../components/logo"
export default class TextWithLogo extends React.Component {
  render() {
    let section
    if (!this.props.removeLink) {
      section = (
        <div data-scroll data-scroll-call='fade-in' className='o-0-5 h-100-p w-100-p ' data-scroll-offset='30%'>
          <Logo className='f-2A2A2A h-30 ' />
          <div className='p-t-100'>
            <h2 className='m-b-40 m-w-400'>{this.props.title}</h2>
            <p className={`body-text m-w-500 ${this.props.textClassName}`}>{this.props.text}</p>
            <LinktoFormButton isLink={this.props.isLink} emailSubject={this.props?.emailSubject || ""} emailBody={this.props?.emailBody || ""} text={this.props.buttonText ? this.props.buttonText : "Learn More"} className={`m-t-30 ${this.props.buttonClass}`} to={this.props.to} />
          </div>
        </div>
      )
    } else {
      section = (
        <div data-scroll data-scroll-call='fade-in' className='o-0-5  ' data-scroll-offset='30%'>
          <Logo className='f-2A2A2A h-30 ' />
          <div className={`${this.props.className}`}>
            <h4 className='m-b-40 m-w-400'>{this.props.title}</h4>
            <h2 className={`body-text m-w-500 ${this.props.textClassName}`}>{this.props.text}</h2>
          </div>
        </div>
      )
    }
    return <div>{section}</div>
  }
}
