import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"

export default class pageLink extends React.Component {
  exit(exit, node) {

    let tl = gsap.timeline()

    tl.to(node.querySelector("#page-tran-w"), {
      position: "fixed",
      maxHeight: "100vh",
    })

    tl.to(
      node.querySelector("#page-tran-w"),
      { height: "100vh", duration: 0.5, ease: "power1.out" },
      "<"
    )
    tl.to(
      node.querySelector("#nav-bar"),
      {
        y: "-100%",
        duration: 0.5,
        ease: "power1.out",
      },
      "<"
    )
    tl.to(node.querySelector(".app"), { visibility: "hidden" }).delay(0.05)

  }
  enter(entry, node) {
    document.querySelector("#nav-bar").classList.add("page-tran")
    let tl = gsap.timeline()
    tl.to(node.querySelector("#title"), { visibility: "visible" })

    tl.to(
      node.querySelector("#title"),
      { opacity: 1, duration: entry.length, ease: "power1.out" }, "-=0.5"

    )
    tl.to(node.querySelector("#nav-bar"), {
      y: "0%",
      duration: entry.length,
      ease: "power1.out",
    }).delay(1)
  }

  render() {
    return (
      <TransitionLink
        onClick={e => {
          e.preventDefault()
        }}
        className="no-events "
        id="page-transition"
        data-target={this.props.target}
        exit={{
          target: this.props.target,
          length: 0.5,
          trigger: ({ exit, node }) => {
            this.exit(exit, node)

          },
        }}
        entry={{
          delay: 0.5,
          length: .75,
          trigger: ({ entry, node }) => {
            this.enter(entry, node)

          },
        }}
        to={this.props.to}
      >
        {this.props.children}
      </TransitionLink>
    )
  }
}
